import cn from 'classnames'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import * as React from 'react'
import { useImmer } from 'use-immer'

import { useQuery } from '@apollo/client'

import { fetchPublicListingsWithFilter } from '../graphql/queries'
import { PUBLIC_LISTING_FILTERS } from '../graphql/queries/filters'
import { FilterQueryVariables, ListingStatus, PublicListingFilters } from '../types/filters'
import { serializeVehicleType } from '../utils/serializeData'
import ListingCard from './ListingCard'
import MultiRangeSlider from './MultiRangeSlider'
import { substoreSettings } from '../utils/substores.config'

type Props = {
  sanityListings: any
}

const ListingsList = (props: Props) => {
  const { sanityListings } = props

  const filterBtn: undefined | React.Ref<HTMLButtonElement> = React.useRef()
  const sectionRef: undefined | React.Ref<HTMLElement> = React.useRef()

  const [filters, setFilters] = useImmer<FilterQueryVariables>({
    priceMax: undefined,
    priceMin: undefined,
    seats: undefined,
    fuelTypes: undefined,
    vehicleMakes: undefined,
    vehicleTypes: undefined,
    equipment: undefined,
    transmissionTypes: undefined,
    statusses: ['PUBLISHED'],
    locationIds: (substoreSettings?.locations as unknown as number[]) ?? undefined,
  })
  const [listings, setListings] = React.useState(sanityListings)
  const [carAvailability, setCarAvailablity] = React.useState(0)
  const [openFilter, setOpenFilter] = React.useState(false)
  const [filterTop, setFilterTop] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  // ---

  const {
    data,
    loading: loadingFilters,
    error,
  } = useQuery<PublicListingFilters>(PUBLIC_LISTING_FILTERS, {
    notifyOnNetworkStatusChange: true,
  })

  // ---

  React.useEffect(() => {
    async function fetchOffers() {
      setLoading(true)
      const listingsWithData = await fetchPublicListingsWithFilter(sanityListings, filters)
      setListings(listingsWithData)
      setLoading(false)
    }

    fetchOffers()
  }, [filters])

  React.useEffect(() => {
    ScrollTrigger.refresh()
  }, [listings])

  React.useLayoutEffect(() => {
    handleSetFilterTop()

    window.addEventListener('resize', handleSetFilterTop)

    return () => {
      window.removeEventListener('resize', handleSetFilterTop)
    }
  }, [])

  // ---

  const handleSetFilterTop = () => {
    const secRect = sectionRef.current.getBoundingClientRect()
    const filterRect = filterBtn.current.getBoundingClientRect()
    const top = filterRect.top - secRect.top
    const bottom = top + filterRect.height

    setFilterTop(bottom)
  }

  const handleSetFilter = (key: keyof FilterQueryVariables | 'price', value: any) => {
    // Override full array
    if (key === 'vehicleMakes' || key === 'vehicleTypes' || key === 'statusses') {
      setFilters(draft => {
        draft[key] = value === '-1' ? undefined : [value]
      })

      // Add/remove to/from array
    } else if (key === 'equipment' || key === 'fuelTypes' || key === 'seats') {
      let cur = filters[key]
      if (value == -1) {
        setFilters(draft => {
          draft[key] = undefined
        })
      } else if (!cur)
        setFilters(draft => {
          draft[key] = [value]
        })
      else {
        const exists = cur.includes(value as never)
        if (exists)
          setFilters(draft => {
            // @ts-ignore
            const newEquipment = draft[key].filter(el => el !== value)

            draft[key] = newEquipment.length ? newEquipment : undefined
          })
        else
          setFilters(draft => {
            draft[key].push(value as never)
          })
      }

      // Set non-array fields
    } else if (key === 'price') {
      setFilters(draft => {
        draft.priceMax = value.max
        draft.priceMin = value.min
      })
    } else {
      setFilters(draft => {
        draft[key] = value
      })
    }
  }

  const handleSort = (val: string) => {
    const value = Number(val)
    const key = value === 1 || value === 2 ? 'price' : value === 3 ? 'added' : 'createdAt'
    const dir = value === 1 || value === 3 ? 'asc' : 'desc'

    const newOrder = listings.sort(function (a, b) {
      const keyA = key === 'price' ? a.data.price : key === 'added' ? a._createdAt : a._createdAt
      const keyB = key === 'price' ? b.data.price : key === 'added' ? b._createdAt : b._createdAt

      if (keyA < keyB) return dir === 'asc' ? -1 : 1
      if (keyA > keyB) return dir === 'asc' ? 1 : -1
      return 0
    })

    setListings(newOrder)
  }

  const handleChangeStatus = (value: ListingStatus) => (e: any) => {
    handleSetFilter('statusses', value)

    const valToNum = value === 'PENDING' ? 1 : 0
    setCarAvailablity(valToNum)
  }

  // ---

  return (
    <section ref={sectionRef} className="landing-offers my-20 px-4 md:px-8 relative">
      <div className="grid lg:grid-cols-12 gap-5 lg:gap-8 mb-10 text-center items-start">
        <div className="lg:col-span-4 2xl:col-span-3 hidden">
          <div className="relative grid grid-cols-2 col-span-1 xl:col-span-10 xl:col-start-2 bg-neutral-200 rounded-full">
            <div
              className="block absolute h-full w-1/2 col-span-1 bg-neutral-500 rounded-full transition duration-500"
              style={{ transform: `translateX(${carAvailability * 100}%)` }}
            />
            <button
              onClick={handleChangeStatus('PUBLISHED')}
              className={cn(
                'relative cursor-pointer col-span-1 py-2 lg:py-1 px-4 center text-center transition duration-500 ease-out',
                {
                  'font-normal text-white': carAvailability === 0,
                }
              )}
            >
              Nuværende biler
            </button>
            <button
              onClick={handleChangeStatus('PENDING')}
              className={cn(
                'relative cursor-pointer col-span-1 py-2 lg:py-1 px-4 center text-center transition duration-500 ease-out',
                {
                  'font-normal text-white': carAvailability === 1,
                }
              )}
            >
              Kommende biler
            </button>
          </div>
        </div>

        <div className="lg:col-span-2 xl:col-span-2 relative">
          <select
            id="sort-dropdown"
            className="inline-flex w-full bg-neutral-200 rounded-full py-2 lg:py-1 !px-4 text-center items-center justify-center border-none !bg-none cursor-pointer"
            onChange={e => handleSort(e.target.value)}
            defaultValue={-1}
          >
            <option value={-1}>Sortér</option>

            <option value={1}>Pris (lav til høj)</option>
            <option value={2}>Pris (høj til lav)</option>
            <option value={3}>Senest tilføjet</option>
          </select>
        </div>

        {openFilter && <div className="fixed w-full h-screen z-40 top-0 left-0" onClick={() => setOpenFilter(false)} />}

        <div className="lg:col-span-2 xl:col-span-2">
          <button
            ref={filterBtn}
            className={cn(
              'inline-flex w-full bg-neutral-200 rounded-full py-2 lg:py-1 px-4 text-center items-center justify-center transition-[border-radius] duration-300 ease-out',
              {
                '!rounded-b-none': openFilter,
              }
            )}
            type="button"
            onClick={() => setOpenFilter(!openFilter)}
          >
            Filter
          </button>

          <div
            className={cn(
              'absolute z-40 w-full md:px-8 rounded-b-3xl rounded-t-none lg:rounded-3xl transition-all duration-300 ease-out',
              {
                'visible opacity-100': openFilter,
                'invisible opacity-0': !openFilter,
              }
            )}
            style={{ inset: `${openFilter ? 0 : -20}px auto auto 0px`, transform: `translate3d(0px, ${filterTop}px, 0px)` }}
          >
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8 w-full p-8 bg-neutral-200 rounded-b-3xl rounded-t-none lg:rounded-3xl">
              <div className="md:col-span-5">
                <div className="grid sm:grid-cols-2 gap-8">
                  <div className="relative">
                    <select
                      id="biltype-dropdown"
                      className="flex justify-between items-center w-full py-3 px-0 bg-transparent border-0 !border-b border-black !appearance-none !outline-none"
                      onChange={e => handleSetFilter('vehicleTypes', e.target.value)}
                      defaultValue={-1}
                    >
                      <option value={-1}>Biltype</option>

                      {data
                        ? data.publicListingFilters.vehicleTypes.map(el => (
                            <option key={el} value={el}>
                              {serializeVehicleType(el)}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>

                  <div className="relative">
                    <select
                      id="producent-dropdown"
                      className="flex justify-between items-center w-full py-3 px-0 bg-transparent border-0 !border-b border-black !appearance-none !outline-none"
                      onChange={e => handleSetFilter('vehicleMakes', e.target.value)}
                      defaultValue={'-1'}
                    >
                      <option value={'-1'}>Producent</option>

                      {data
                        ? data.publicListingFilters.vehicleMakes.map(el => (
                            <option key={el.id} value={el.name}>
                              {el.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </div>

                <div className="mt-10">
                  {data && (
                    <MultiRangeSlider
                      min={100}
                      max={data.publicListingFilters.priceMax?.amount || 100}
                      step={100}
                      onChange={values => handleSetFilter('price', values)}
                    />
                  )}
                </div>
              </div>

              <div className="md:col-span-5 md:col-start-8">
                <ul className="grid grid-cols-2 gap-2 m-0 px-0 py-3 list-none" aria-labelledby="filter-button">
                  {!loadingFilters &&
                    data &&
                    data.publicListingFilters.equipment.map(el => {
                      const checked = filters.equipment?.includes(el.id)
                      return (
                        <li key={el.id} className="flex items-center mr-4">
                          <input
                            checked={checked}
                            id={`${el.id}`}
                            type="checkbox"
                            value={el.id}
                            onClick={() => handleSetFilter('equipment', el.id)}
                            className="w-5 h-5 text-black bg-transparent border-black rounded-full ring-offset-0 !shadow-none ring-0 focus:ring-0 focus:ring-transparent"
                          />
                          <label htmlFor={`${el.id}`} className="ml-3 text-left">
                            {el.name}
                          </label>
                        </li>
                      )
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:col-span-4 2xl:col-span-5 flex border border-black rounded-full items-center">
          <input type="text" placeholder="Søg" className="block w-full py-1 px-4 rounded-l-full placeholder-black border-none" />
          <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-2">
            <path
              d="M1 24L8.03694 16.9935M15.3177 1C9.97053 1 5.63542 5.31635 5.63542 10.6404C5.63542 15.9644 9.97053 20.2808 15.3177 20.2808C20.6649 20.2808 25 15.9644 25 10.6404C25 5.31635 20.6649 1 15.3177 1Z"
              stroke="black"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </div>

      <div className={cn('flex items-center transition-opacity', { 'opacity-50': loading })}>
        {listings.length ? (
          <ul className="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-3 gap-8 list-none p-0 m-0">
            {listings.map(el => {
              return <ListingCard listing={el} key={el._id} />
            })}
          </ul>
        ) : (
          <p className="text-center w-full text-2xl sm:text-3xl my-28 px-2">
            Der er ingen biler, der matcher dine søgekriterier.
          </p>
        )}
      </div>
    </section>
  )
}

export default ListingsList
