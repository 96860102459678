import React from 'react'

import { formatPrice } from '../utils/utils'

export type MultiRangeSliderProps = {
  min: number
  max: number
  step: number
  onChange: Function
}

const MultiRangeSlider = (props: MultiRangeSliderProps) => {
  const { min, max, step, onChange } = props

  const [minVal, setMinVal] = React.useState(min)
  const [maxVal, setMaxVal] = React.useState(max)
  const minValRef = React.useRef(min)
  const maxValRef = React.useRef(max)
  const range = React.useRef(null)

  const getPercent = React.useCallback(value => Math.round(((value - min) / (max - min)) * 100), [min, max])

  // ---

  React.useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, getPercent])

  React.useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal, getPercent])

  // ---

  const handleChange = (end: 'min' | 'max', value: number) => {
    if (end === 'min') {
      setMinVal(value)
      minValRef.current = value
    } else {
      setMaxVal(value)
      maxValRef.current = value
    }

    onChange({ min: end === 'min' ? value : minVal, max: end === 'max' ? value : maxVal })
  }

  // ---

  return (
    <div className="">
      <div className="text-left mb-6">
        <p>Pris pr. 30. dag</p>
        <p>
          {formatPrice(minVal)} - {formatPrice(maxVal)}
        </p>
      </div>
      <div className="relative flex item-center justify-center">
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          step={step || 1}
          onChange={event => {
            const value = Math.min(Number(event.target.value), maxVal - step)
            handleChange('min', value)
          }}
          className="multiRangeSlider-thumb pointer-events-none absolute h-0 w-full outline-none z-30"
          style={{ zIndex: minVal > max - 100 && '5' }}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          step={step || 1}
          onChange={event => {
            const value = Math.max(Number(event.target.value), minVal + step)
            handleChange('max', value)
          }}
          className="multiRangeSlider-thumb pointer-events-none absolute h-0 w-full outline-none z-40"
        />

        <div className="slider relative w-full">
          <div className="slider__track absolute h-[1px] bg-neutral-400 w-full z-10" />
          <div ref={range} className="slider__range absolute h-[1px] bg-black z-20" />
        </div>
      </div>
    </div>
  )
}

export default MultiRangeSlider
