import { gql } from '@apollo/client'

import { client } from '../apollo-client'

export const PUBLIC_LISTING_FILTERS = gql`
  query PublicListingFilters {
    publicListingFilters {
      equipment {
        id
        name
      }
      fuelTypes
      priceMax {
        id
        amount
      }
      priceMin {
        id
        amount
      }
      seats
      vehicleMakes {
        id
        name
      }
      vehicleTypes
      transmissionTypes
      statusses
    }
  }
`
